import React from 'react';
import { Link } from 'gatsby';
import slugTransform from 'slug';
import moment from 'moment';
import styled from 'styled-components';

import Masthead from '../components/masthead';

const StyledAbout = styled.div`
	h2 {
		font-family: ${(p) => p.theme.fonts.title};
		font-weight: 700;
	}
	.info-tiles {
		.columns {
			display: flex;
			flex-flow: column;
			@media all and (min-width: 1024px) {
				flex-flow: wrap;
			}
			.info-content {
				&.image-holder {
					height: 30rem;
					@media all and (min-width: 1024px) {
						height: 100%;
					}
				}
				padding: 3rem;
				.text-content {
					padding: 0;
					@media all and (min-width: 1024px) {
						padding: 10%;
					}
					text-align: left;
					h2 {
						color: ${(p) => p.theme.color.dark};
						margin-bottom: 1rem;
					}
					p {
						font-family: ${(p) => p.theme.fonts.body};
					}
					.button-container {
						margin-top: 2rem;
						.button-wrap {
							display: inline-block;
							&:first-child {
								margin-right: 2rem;
							}
							a {
								font-weight: 700;
							}
						}
					}
				}
			}
			.column:nth-child(1) .text-content {
				padding-left: 1rem;
			}

			@media all and (max-width: 1023px) {
				.column:nth-child(3) {
					order: 4;
				}
				.column .text-content {
					padding: 0 1rem 1rem;
				}
			}
		}
		@media all and (max-width: 500px) {
			.columns .info-content {
				&.image-holder {
					height: 20rem;
				}
				padding: 2rem ${(p) => p.theme.gutterMobile};
				.text-content {
					padding: 0 0 1rem !important;
				}
			}
		}
		@media all and (max-width: 400px) {
			.columns .info-content .text-content .button-container {
				width: 100% !important;
				display: flex;
				justify-content: center;
				align-items: flex-start;
				flex-direction: column;
				.button-wrap {
					&:first-child {
						margin-right: 0;
					}
					&:last-child {
						margin-top: 1rem;
					}
					a {
						display: block;
					}
				}
			}
		}
	}

	.staff-roster {
		background-color: ${(p) => p.theme.color.blue};
		color: #fff;
		padding: 4rem ${(p) => p.theme.gutter} 0;
		.staff-holder {
			p {
				font-family: ${(p) => p.theme.fonts.body};
			}
			.staff-content-holder {
				.columns {
					padding: 2rem 0;
					text-align: left;
					font-family: ${(p) => p.theme.fonts.title};

					display: flex;
					.img-container {
						max-width: 200px;
						margin: 0 auto;
						img {
							border-radius: 50%;
						}
					}
					.column {
						width: 50%;
						flex: none;
						@media all and (max-width: 600px) {
							width: 100%;
						}
						padding-bottom: 2rem;
						.text-content {
							text-align: left;
							display: inline-block;
							p {
								font-family: ${(p) => p.theme.fonts.title};
								font-weight: 700;
								font-size: 1.25rem;
							}
							a {
								font-family: ${(p) => p.theme.fonts.body};

								font-size: 0.8rem;
							}
						}
					}
				}
			}
		}
		@media all and (min-width: 1200px) {
			padding: 4rem ${(p) => p.theme.gutterWide} 0;
		}
		@media all and (min-width: 1600px) {
			.staff-holder .staff-content-holder .columns {
				.img-container {
					max-width: 250px;
				}
				.column .text-content {
					p {
						font-size: 1.75rem;
					}
					a {
						font-size: 1.1rem;
					}
				}
			}
		}
		@media all and (max-width: 500px) {
			padding: 2rem ${(p) => p.theme.gutterMobile} 0;
		}
	}

	.contact-broker {
		background-color: ${(p) => p.theme.color.yellow};
		color: #fff;
		font-family: ${(p) => p.theme.fonts.title};
		font-weight: 700;
		padding: 0 2rem;
		.broker-content {
			padding: 3rem 0 4rem;
			span {
				font-size: 2.2rem;
				line-height: 2.6rem;
				a {
					text-decoration: none;
					color: #fff;
					font-size: 2.2rem;
					position: relative;
					&::after {
						content: '';
						background-color: #fff;
						height: 3px;
						width: 100%;
						left: 0;
						bottom: 0;
						position: absolute;
						opacity: 0;
						transition: 0.5s;
					}
					&:hover::after {
						opacity: 1;
					}
				}
			}
			@media all and (min-width: 1600px) {
				span {
					font-size: 2.8rem;
					line-height: 3.5rem;
					a {
						font-size: 2.8rem;
					}
				}
			}
		}
	}

	.featured-articles {
		font-family: ${(p) => p.theme.fonts.title};

		.article-holder {
			.columns {
				.column {
					.featured-article {
						height: 100%;
						padding: 3rem 5rem 3rem ${(p) => p.theme.gutter};
						background-size: cover;
						position: relative;
						.overlay {
							width: 100%;
							height: 100%;
							position: absolute;
							left: 0;
							top: 0;
							background-color: rgba(0, 0, 0, 0.6);
							z-index: 1;
						}
						h2,
						.text-holder,
						.button-holder,
						.title-holder {
							position: relative;
							z-index: 10;
						}
						h2 {
							margin-bottom: 1rem;
							color: #fff;
						}
						.button-holder {
							padding-top: 2rem;
							padding-bottom: 2rem;
						}
						.text-holder {
							font-family: ${(p) => p.theme.fonts.body};
							color: #fff;
						}
						.title-holder {
							font-family: ${(p) => p.theme.fonts.title};
							font-weight: 700;
							color: #fff;
						}
					}

					.feat-border {
						font-family: ${(p) => p.theme.fonts.title};

						height: 50%;
						&:last-child {
							background-color: #e6e6e6;
						}

						.content-holder {
							padding: 3rem;
							h2 {
								color: ${(p) => p.theme.color.darkBlue};
								transition: 0.5s;
								&:hover {
									color: ${(p) => p.theme.color.blue};
								}
							}
							p {
								color: ${(p) => p.theme.color.darkBlue};
							}
						}
					}
					@media all and (max-width: 500px) {
						.featured-article,
						.feat-border .content-holder {
							padding: 2rem ${(p) => p.theme.gutterMobile};
						}
					}
				}
			}
		}
	}
	@media all and (max-width: 1023px) {
		.info-tiles .columns .info-content .text-content .button-container {
			width: 300px;
		}
	}
`;

export default ({ data }) => {
	const {
		banner,
		section1,
		cta,
	} = data.allMarkdownRemark.edges[0].node.frontmatter;
	const posts = data.posts.nodes;

	const {
		featuredPost1,
		featuredPost2,
		featuredPost3,
	} = data.featuredPosts.nodes[0];
	const featuredPosts = [featuredPost1, featuredPost2, featuredPost3];
	let featuredArray = [];

	// Iterate through all posts and add featured posts from settings.json
	// to featuredArray in chronological order of the featured posts
	for (let i in featuredPosts) {
		for (let j in posts) {
			if (featuredPosts[i] === posts[j].shortTitle) {
				featuredArray.push(posts[j]);
			}
		}
	}

	let allPosts = posts;

	// While featuredPosts array has less than 3 post titles in it, add latest posts in order
	while (featuredArray.length < 3) {
		featuredArray.push(allPosts[0]);
		allPosts = allPosts.slice(1);
	}

	return (
		<StyledAbout>
			<Masthead
				title={banner.title}
				image={banner.image}
				description={banner.sub}
			/>
			<div className="info-tiles">
				<div className="columns is-multiline is-gapless has-text-centered">
					<div className="column is-half-desktop is-full-tablet is-full-mobile">
						<div className="info-content">
							<div className="text-content">
								<h2>{section1.title_left}</h2>
								<p>{section1.content_left}</p>
							</div>
						</div>
					</div>
					<div className="column is-half-desktop is-full-tablet is-full-mobile">
						<div
							className="info-content image-holder"
							style={{
								backgroundImage: `url(${section1.image_right})`,
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center center',
								backgroundSize: 'cover',
								width: '100%',
							}}
						/>
					</div>
					<div className="column is-half-desktop is-full-tablet is-full-mobile">
						<div
							className="info-content image-holder"
							style={{
								backgroundImage: `url(${section1.image_left})`,
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center center',
								backgroundSize: 'cover',
								width: '100%',
							}}
						/>
					</div>
					<div className="column is-half-desktop is-full-tablet is-full-mobile">
						<div className="info-content">
							<div className="text-content">
								<h2>{section1.title_right}</h2>
								<p>{section1.content_right}</p>
								<div className="button-container">
									<div className="button-wrap">
										<a className="ec-button" href={section1.button1.link}>
											{section1.button1.text}
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="contact-broker">
				<div className="broker-content has-text-centered">
					<span>{cta.text1}</span>
					<br />
					<span>
						<Link to={cta.link.href}>{cta.link.text}</Link> {cta.text2}
					</span>
				</div>
			</div>

			<div className="featured-articles">
				<div className="article-holder">
					<div className="columns is-gapless">
						<div className="column is-half">
							<div
								className="featured-article"
								style={{
									backgroundImage: `url(${featuredArray[0].galleryImage})`,
								}}
							>
								<div className="overlay" />
								<p className="title-holder">Featured Article:</p>
								<Link
									to={`/resources/${slugTransform(featuredArray[0].shortTitle, {
										lower: true,
									})}/`}
								>
									<h2>{featuredArray[0].shortTitle}</h2>
								</Link>
								<p className="text-holder">{featuredArray[0].excerpt}</p>
								<div className="button-holder">
									<Link
										to={`/resources/${slugTransform(
											featuredArray[0].shortTitle,
											{
												lower: true,
											}
										)}/`}
										className="ec-button"
									>
										Learn More
									</Link>
								</div>
							</div>
						</div>
						<div className="column  is-half">
							{featuredArray
								.slice(1, featuredArray.length)
								.map((item, index) => (
									<div className="feat-border" key={index}>
										<div className="content-holder">
											<p>{moment(item.date).format('MMMM DD, YYYY')}</p>
											<Link
												to={`/resources/${slugTransform(item.shortTitle, {
													lower: true,
												})}/`}
											>
												<h2>{item.title}</h2>
											</Link>
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
		</StyledAbout>
	);
};
