import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import AboutTemplate from '../templates/about-template';
import SEO from '../components/seo';

const About = ({ data }) => {
	return (
		<Layout page="About">
			<SEO
				title="About"
				description={
					data.allMarkdownRemark.edges[0].node.frontmatter.description
				}
			/>
			<AboutTemplate data={data} />
		</Layout>
	);
};

export default About;

export const query = graphql`
	query {
		allMarkdownRemark(filter: { frontmatter: { slug: { eq: "about" } } }) {
			edges {
				node {
					frontmatter {
						description
						banner {
							title
							image
							sub
						}
						section1 {
							title_left
							title_right
							content_left
							content_right
							image_left
							image_right
							button1 {
								text
								link
							}
						}
						cta {
							text1
							text2
							link {
								text
								href
							}
						}
					}
				}
			}
		}
		featuredPosts: allSettingsJson {
			nodes {
				featuredPost1
				featuredPost2
				featuredPost3
			}
		}
		posts: allPostsJson(sort: { fields: date, order: DESC }) {
			nodes {
				title
				shortTitle
				date
				excerpt
				galleryImage
			}
		}
	}
`;
