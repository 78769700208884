import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledMasthead = styled.div`
	background-image: url(${(p) => p.img});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 0 ${(p) => p.theme.gutter};
	height: 35rem;
	margin-top: 2rem;
	padding-top: 3rem;
	display: flex;
	.mast-container {
		margin-top: 5.3rem;
		display: flex;
		align-items: center;
		width: 100%;
		.mast-content {
			background-color: rgba(0, 0, 0, 0.7);
			width: ${(p) => p.normalWidth};
			padding: 2rem 2.5rem;
			&.disclosures {
				width: 410px;
			}
			h1,
			h2 {
				font-family: ${(p) => p.theme.fonts.title};
				font-weight: 700;
				color: #fff;
				font-size: 2rem;
				line-height: 2rem;
			}
			p {
				font-family: ${(p) => p.theme.fonts.body};
				color: #fff;
				margin-top: 1rem;
			}

			.mastButton {
				margin-top: 2rem;
			}
		}
	}
	@media all and (min-width: 1200px) {
		padding: 3rem ${(p) => p.theme.gutterWide} 0;
	}
	@media all and (min-width: 1600px) {
		margin-top: 2.5rem;
		height: 48rem;
		padding: 0 ${(p) => p.theme.gutterXWide};
		.mast-container {
			margin-top: 6.8rem;
			.mast-content {
				width: ${(p) => p.wideWidth};
				h1,
				h2 {
					line-height: 2.5rem;
					font-size: 2.5rem;
				}
				p {
					font-size: 1.3rem;
					line-height: 1.7rem;
				}
			}
		}
	}
	@media all and (max-width: 950px) {
		margin-top: 10.3rem;
		height: 26.5rem;
		padding-top: 0;
		.mast-container {
			margin-top: 0;
		}
	}
	@media all and (max-width: 550px) {
		padding: 2rem;
		height: auto;
		.mast-container .mast-content {
			padding: 1rem 1.5rem 2rem;
		}
	}
	@media all and (max-width: 500px) {
		padding: ${(p) => p.theme.gutterMobile};
		.mast-container .mast-content {
			width: 100%;
			padding: 1rem 1rem 1.5rem;
		}
	}
	@media all and (max-width: 350px) {
		.mast-container .mast-content .mastButton .ec-button {
			padding: 0.5rem 0.8rem;
		}
	}
`;

const Masthead = ({ title, title2, description, button, image, page }) => {
	return (
		<StyledMasthead
			img={image}
			normalWidth={button ? '490px' : '405px'}
			wideWidth={button ? '610px' : '485px'}
		>
			<div className="mast-container">
				<div
					className={
						page === 'disclosures' ? 'mast-content disclosures' : 'mast-content'
					}
				>
					{page !== 'home' && page !== 'post' && <h1>{title}</h1>}
					{(page === 'home' || page === 'post') && <h2>{title}</h2>}
					{title2 && <h2>{title2}</h2>}
					{description && <p>{description}</p>}
					{button && (
						<div className="mastButton">
							<Link to={button.link} className="ec-button">
								{button.text}
							</Link>
						</div>
					)}
				</div>
			</div>
		</StyledMasthead>
	);
};

export default Masthead;
